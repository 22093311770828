
import { Component, Watch } from "vue-property-decorator";
import Editor from "./editor";
@Component({
  mixins: [Editor],
})
export default class EnumEditor extends Editor {
  items: any = [];
  loaded: boolean = false;

  get type(): string {
    return this.field?.config?.type ?? "btn";
  }

  changeByBtn(val: any) {
    this.value = val;
    this.validate();
    this.emit();
  }
  async created() {
    this.items = await this.actions[this.config.getItems]();
    this.loaded = true;
  }

  @Watch("model", { deep: true })
  async onModelChanged() {
    if (!(this.watchers?.length > 0)) return;
    for (const watcher of this.watchers) {
      const { changeModel, changeAttrs, changeValidations, changeItems } = watcher;
      if (!!changeModel) {
        await this.actions[changeModel](this.model);
      }

      if (!!changeAttrs) {
        this.attrs = await this.actions[changeAttrs](this.model);
      }

      if (!!changeValidations) {
        this.validations = await this.actions[changeValidations]();
      }

      if (!!changeItems) {
        this.items = await this.actions[changeItems](this.model);
      }
    }
  }
}
