export const initCurrencyRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/currencies",
          name: "currency",
          component: () => import("@/pages/manage/currencies/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
            ],
          },
        },
        {
          path: "/manage/currencies/:id",
          name: "currency-id",
          component: () => import("@/pages/manage/currencies/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
            ],
          },
        },
      ];
