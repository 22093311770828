export const initNewsRoutes = (config: any, enabled: boolean = true) =>
    !enabled
        ? []
        : [
            {
                path: "/manage/news",
                name: "news",
                component: () => import("@/pages/manage/news/index.vue"),
                meta: {
                    requiresAuth: true,
                    roles: [config.roles.administrators, config.roles.siteEditors],
                },
            },
            {
                path: "/manage/news/:id",
                name: "news-id",
                component: () => import("@/pages/manage/news/id.vue"),
                meta: {
                    requiresAuth: true,
                    roles: [config.roles.administrators, config.roles.siteEditors],
                },
            },
        ];